import React, {Component} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {APIURL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL} from "../../../config/config";
import axios from "axios";
import {AlertError, AlertSuccess} from "../../Alert/Alert";
import Loading from "../../Loading";
import Swal from "sweetalert2";

// 365car เปลี่ยนกรณียังไม่ขาย ลูกค้า preorder พอได้รถจริง แล้วค่อยเปลี่ยนคัน
class CarBookChangeCar extends Component{
    constructor(props) {
        super(props);
        this.state = {
            car_list: [],
            car_select: null,
            isLoading: false,
        }
        this.searchCar = this.searchCar.bind(this);
        this.getDataCarSearch = this.getDataCarSearch.bind(this);
        this.onCarSelect = this.onCarSelect.bind(this);
        this.updateCarBookChangeCar = this.updateCarBookChangeCar.bind(this);
    }

    searchCar (e) {
        let search_text = e.target.value
        if (search_text === '') {
            if (this.state.car_list.length > 0) {
                this.setState({car_list: [], car_select: null, is_car_search: false })
            }
            return
        }

        if (search_text.length < 3) {
            return
        }

        this.getDataCarSearch(search_text)
    }

    getDataCarSearch (search_text) {
        // เฉพาะรถที่ยังไม่ขาย
        axios.get(APIURL + '/car/search/' + search_text)
            .then(res => {
                this.setState({car_list: res.data, is_car_search: true})
            }).catch(error => {
            this.setState({car_list: [], is_car_search: false})
        })
    }

    onCarSelect(car) {
        this.setState({
            car_select: car,
            is_car_search: false
        });
    }

    updateCarBookChangeCar() {
        let car_select = this.state.car_select
        if (car_select === null) {
            AlertError('กรุณาเลือกรถคันใหม่')
            return
        }

        // car_book_id, car_sell_pay_id, car_id_new
        let car_sell_pay = this.props.car_sell_pay

        let data = {
            car_book_id: car_sell_pay.car_book_id,
            car_sell_pay_id: car_sell_pay.car_sell_pay_id,
            car_id_new: car_select.car_id,
            car_id_old: car_sell_pay.car_id,
            change_type: 'book'
        }

        // console.log(data)
        // return;

            let car_license_plate = car_sell_pay.car_license_plate_new === '' ?  car_sell_pay.car_license_plate_old : car_sell_pay.car_license_plate_new
            let province_name = car_sell_pay.car_license_plate_new === '' ?  car_sell_pay.province_name_old : car_sell_pay.province_name_new
            let car_license = car_license_plate+' '+province_name

            let car_select_license_plate = ''
            let car_select_province_name = ''

            car_select_license_plate = car_select.car_license_plate_new !== '' &&  car_select.car_license_plate_new !==  car_select.car_license_plate_old ? car_select.car_license_plate_new : car_select.car_license_plate_old
            car_select_province_name = car_select.car_license_plate_new !== '' &&  car_select.car_license_plate_new !==  car_select.car_license_plate_old ? car_select.province_name_new : car_select.province_name_old

            Swal.fire({
                title: 'ยืนยันการเปลี่ยนรถจากทะเบียน '+car_license+ ' '+'เป็นรถทะเบียน '+car_select_license_plate+ ' ' +car_select_province_name,
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
            }).then((result) => {
                if (result.value) {

                    this.setState({
                        isLoading: true
                    }, () => {
                        axios.post(APIURL + '/car_book/change_car', data)
                            .then(res => {
                                if (res.status === 200) {
                                    AlertSuccess('เปลี่ยนรถเรียบร้อยแล้ว');
                                    this.setState({isLoading: false})
                                    this.props.onToggle()
                                    this.props.onReload()
                                }
                            }).catch(error => {
                            console.log('error: ', error)
                        })
                    })
                }
            })





    }

    render() {
        let isOpen = this.props.isOpen
        let car_sell_pay = this.props.car_sell_pay
        let car_select = this.state.car_select

        let fullname = car_sell_pay.customer_name + ' '+car_sell_pay.customer_lastname
        let customer_mobile = car_sell_pay.customer_mobile

        let car_license_plate = car_sell_pay.car_license_plate_new === '' ?  car_sell_pay.car_license_plate_old : car_sell_pay.car_license_plate_new
        let province_name = car_sell_pay.car_license_plate_new === '' ?  car_sell_pay.province_name_old : car_sell_pay.province_name_new
        let car_license = car_license_plate+' '+province_name

        let image_url = '';

        if (car_sell_pay.car_upload_filename.includes('https')) {
            image_url = car_sell_pay.car_upload_filename
        } else {
            image_url =  IMAGE_FULL_URL+car_sell_pay.car_upload_filename
            image_url = ( typeof car_sell_pay.car_upload_filename === undefined ) ? IMAGE_NOPHOTO_URL : image_url
        }

        let search_car_list = this.state.car_list.map((car,index)=>{

            let car_license_plate = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ?
                car.car_license_plate_new : car.car_license_plate_old
            let province_name = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ?
                car.province_name_new : car.province_name_old
            return(
                <tr key={index}>
                    <td className="text-left">
                        {car.car_name} <br/>
                        ทะเบียน : {car_license_plate} &nbsp;{province_name}
                    </td>
                    <td>
                        <button
                            onClick={()=>this.onCarSelect(car)}
                            type="button"
                            className="btn btn-primary"
                        >
                           &nbsp; เลือก &nbsp;
                        </button>
                    </td>
                </tr>
            )
        })

        let car_select_license_plate = ''
        let car_select_province_name = ''
        let car_select_image_url = ''

        if (car_select !== null) {
            car_select_license_plate = car_select.car_license_plate_new !== '' &&  car_select.car_license_plate_new !==  car_select.car_license_plate_old ? car_select.car_license_plate_new : car_select.car_license_plate_old
            car_select_province_name = car_select.car_license_plate_new !== '' &&  car_select.car_license_plate_new !==  car_select.car_license_plate_old ? car_select.province_name_new : car_select.province_name_old


            if (car_select.car_upload_filename.includes('https')) {
                car_select_image_url = car_select.car_upload_filename
            } else {
                car_select_image_url =  IMAGE_FULL_URL+car_select.car_upload_filename
                car_select_image_url = ( typeof car_select.car_upload_filename === undefined ) ? IMAGE_NOPHOTO_URL : car_select_image_url
            }
        }

        let car_select_license = car_select_license_plate+' '+car_select_province_name

        // 'cursor' : 'pointer',
        return <>

            <Modal className="modal-lg modal-primary"
                   isOpen={isOpen}
                   toggle={this.props.onToggle}
            >
                <ModalHeader toggle={this.props.onToggle}>เปลี่ยนรถ</ModalHeader>
                <ModalBody>

                    <Loading isLoading={this.state.isLoading} />

                    <div className="card-block">
                        <div className="row">
                            <div className="form-group col-4">
                                <label htmlFor="fullname">ชื่อ-นามสกุล</label>
                                <input type="text"
                                       className="form-control"
                                       value={fullname}
                                       readOnly={true}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label htmlFor="customer_mobile">เบอร์โทร</label>
                                <input type="text"
                                       className="form-control"
                                       value={customer_mobile}
                                       readOnly={true}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label htmlFor="customer_card_id">บัตรประชาชน</label>
                                <input type="text"
                                       className="form-control"
                                       value={car_sell_pay.customer_card_id}
                                       readOnly={true}
                                />
                            </div>

                            <div className="form-group col-12">
                                <input type="text"
                                       className="form-control"
                                       value={'ที่อยู่: '+ car_sell_pay.customer_address}
                                       readOnly={true}
                                />
                            </div>

                        </div>

                        <hr/>

                        <div className="row">
                            <div className="col-6">

                                <div className="row mb-2">
                                    <div className="col-6">
                                       <strong> คันปัจจุบัน </strong>
                                    </div>
                                </div>


                                <div className="row"> <div className="col-3"> ยี่ห้อ : </div> <div className="col-9"> {car_sell_pay.car_brand_name} </div> </div>
                                <div className="row"> <div className="col-3"> รุ่น : </div> <div className="col-9"> {car_sell_pay.car_model_name}</div> </div>
                                <div className="row">  <div className="col-3"> รุ่นย่อย : </div> <div className="col-9"> {car_sell_pay.car_sub_model_name} </div></div>
                                <div className="row"> <div className="col-3"> สี : </div> <div className="col-9"> {car_sell_pay.car_color_name} </div></div>
                                <div className="row"> <div className="col-3"> ปี : </div> <div className="col-9"> {car_sell_pay.car_year_name} </div></div>
                                <div className="row"> <div className="col-3"> เกียร์ : </div> <div className="col-9"> {car_sell_pay.car_gear_name} </div></div>
                                <div className="row"> <div className="col-3"> ทะเบียน : </div> <div className="col-9"> {car_license} </div></div>
                                <div className="row"> <div className="col-3"> สถานะ : </div> <div className="col-9"> {car_sell_pay.car_status_name} </div></div>
                                <div className="row">
                                    <div className="col-3"> รูปรถ : </div>
                                    <div className="col-9">
                                        <img id={'img_'+car_sell_pay.car_sell_pay_id}
                                             src={image_url}
                                             style={{ 'width': '150px', 'height': 'auto'}}
                                             alt="car"
                                        />
                                    </div>
                                </div>


                            </div>
                            <div className="col-6">
                                <div className="row ">
                                    <div className="col-3">
                                        <strong> คันใหม่ </strong>
                                    </div>
                                    <div className="col-8">
                                        <div className="input-group ">
                                            <input onChange={this.searchCar}
                                                   name="car_search"
                                                   placeholder="ค้นหาตามทะเบียนรถ (อย่างน้อย 3 ตัวอักษร)"
                                                   className="form-control"
                                                   size="16"
                                                   type="text"/>
                                        </div>
                                    </div>
                                </div>

                                <div className={ this.state.is_car_search ? " col-12 " : ' hidden' }>
                                    <table className="table table-striped ml-2">
                                        <tbody>
                                        {search_car_list}
                                        </tbody>
                                    </table>
                                </div>

                                {
                                   car_select !== null ? <>
                                        <div className="col-12">


                                            <div className="row"> <div className="col-3"> ยี่ห้อ : </div> <div className="col-9"> {car_select.car_brand_name} </div> </div>
                                            <div className="row"> <div className="col-3"> รุ่น : </div> <div className="col-9"> {car_select.car_model_name}</div> </div>
                                            <div className="row">  <div className="col-3"> รุ่นย่อย : </div> <div className="col-9"> {car_select.car_sub_model_name} </div></div>
                                            <div className="row"> <div className="col-3"> สี : </div> <div className="col-9"> {car_select.car_color_name} </div></div>
                                            <div className="row"> <div className="col-3"> ปี : </div> <div className="col-9"> {car_select.car_year_name} </div></div>
                                            <div className="row"> <div className="col-3"> เกียร์ : </div> <div className="col-9"> {car_select.car_gear_name} </div></div>
                                            <div className="row"> <div className="col-3"> ทะเบียน : </div> <div className="col-9"> {car_select_license} </div></div>
                                            <div className="row"> <div className="col-3"> สถานะ : </div> <div className="col-9"> {car_select.car_status_name} </div></div>
                                            <div className="row">
                                                <div className="col-3"> รูปรถ : </div>
                                                <div className="col-9">
                                                    <img id={'img_'+car_select.car_id}
                                                         src={car_select_image_url}
                                                         style={{ 'width': '150px', 'height': 'auto'}}
                                                         alt="car"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </> : null
                                }

                            </div>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>

                    <button type="button"
                            onClick={() => this.props.onToggle()}
                            className="btn btn-sm btn-danger mr-2"
                            style={{width: 80}}
                    >
                        <i className="fa fa-window-close-o"/>
                        &nbsp;&nbsp; ปิด  &nbsp;&nbsp;
                    </button>{' '}

                    <Button color="primary"
                            style={{width: 80}}
                            onClick={() => this.updateCarBookChangeCar()}
                    > บันทึก
                    </Button>

                </ModalFooter>
            </Modal>

            </>
    }
}

export default CarBookChangeCar