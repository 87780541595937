
// export const DocUtil = {
//   isPDF(fileNameOrUrl) {
//     const splitData = fileNameOrUrl.split('.');
//     const lastIndex = splitData.length - 1
//     const ext = splitData[lastIndex]
//     console.log('ext=', ext)
//     const isPdf = ext === 'pdf' ? true : false
//     console.log('isPdf=', isPdf)
//     return isPdf
//   }
// }

class DocUtil {
  isPDF(fileNameOrUrl) {
    const splitData = fileNameOrUrl.split('.');
    const lastIndex = splitData.length - 1
    const ext = splitData[lastIndex]
    const isPdf = ext === 'pdf' ? true : false
    return isPdf
  }

  isDoc(fileNameOrUrl) {
    const splitData = fileNameOrUrl.split('.');
    const lastIndex = splitData.length - 1
    const ext = splitData[lastIndex]
    const isDoc = ext === 'doc' || ext === 'docx' ? true : false
    return isDoc
  }

  isImage(fileName) {
    return fileName.match(/.(jpg|jpeg|png|gif)$/i)
  }

  replaceStar(str) {
    let len = str.length

    if(len < 10){
      return str;
    }

    let first = str.substring(0,3)
    let last = str.substring(len-4, len-1)

    let star = Array(len-6).join("*")
    let newStr = first+star+last
    return newStr
  }

  getCarDocTypeName(car_doc_type) {
    if(car_doc_type === 'normal') {
      return 'เอกสารรถ'
    } else if (car_doc_type === 'secret') {
      return 'เอกสารลับ'
    } else if (car_doc_type === 'sell_doc') {
      return 'เอกสารขาย'
    }
  }

}

export default new DocUtil();
