import React, { Component } from 'react';
import Loading from "../../Loading";
import docUtil from "../../../utils/docUtil";
import {AlertError, AlertSuccess, AlertWarning} from "../../Alert/Alert";
import {APIURL, MAX_FILE_SIZE, MAX_FILE_SIZE_LIMIT, MB_SIZE} from "../../../config/config";
import axios from "axios";
import {Progress} from "reactstrap";
// import {marginRight} from "html2canvas/dist/types/css/property-descriptors/margin";
// import {connect} from "react-redux";
// import CarDocList from "../CarDocList";
const randomString = require('random-string')

class CarDocAddMulti extends  Component{

    constructor (props){
        super(props)

        this.inputRef = React.createRef();

        this.state = {
            car: this.props.car,
            isLoading: false,
            w_car_doc_name: false,
            w_car_doc_file: false,
            car_doc_name: '',
            fileList: [],
            previewList: [],
            progress: {stated: false, pc: 0},
            doc_upload_code: ''
        }

        // this.handleSubmit = this.handleSubmit.bind(this)
        this.getDocTypeName = this.getDocTypeName.bind(this)
        this.handleDrag = this.handleDrag.bind(this)
        this.handleDrop = this.handleDrop.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.uploadFiles = this.uploadFiles.bind(this)
        this.getPreviewImage = this.getPreviewImage.bind(this)
        this.onButtonClick = this.onButtonClick.bind(this)

        // const inputRef = React.useRef(null);

    }

    componentDidMount() {

        if (this.state.doc_upload_code === '') {
            let random_code = randomString({
                length: 20,
                numeric: true,
                letters: true,
                special: false,
                exclude: ['o', 'l']
            })

            this.setState({doc_upload_code: random_code})
        }
    }

    onButtonClick() {
        // this.printRef.current
        this.inputRef.current.click();
    };

    handleChange(e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            // at least one file has been selected so do something
            // handleFiles(e.target.files);
            // console.log(e.target.files)
            // console.log('e.target.files.length=', e.target.files.length)
            // const fileSize = fileInput.files[0].size;
            // const maxSize = 1024 * 1024 * 10; // 10MB
            let files = e.target.files
            let previewList = []
            for(let i=0; i < files.length; i++) {
                let selectedFile = files[i];
                let fileName = selectedFile.name;
                let fileSize = selectedFile.size;
                console.log('fileSize=', fileSize, '|', MAX_FILE_SIZE)
                if(fileSize > MAX_FILE_SIZE) {
                    const fileMB = Math.round((fileSize / MB_SIZE));
                    AlertError('ไฟล์ '+fileName+' มีขนาด '+fileMB+ ' Mb มากกว่า '+MAX_FILE_SIZE_LIMIT+' Mb ไม่สามารถอัพโหลดได้')
                    previewList = []
                    break;
                }

                const objectUrl = URL.createObjectURL(selectedFile)
                previewList.push(objectUrl)
            }

            if(previewList.length !== 0) {
                this.setState({
                    fileList: e.target.files,
                    previewList: previewList
                });
            }
        }
    };

    handleDrag(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            this.setState({dragActive: true})
        } else if (e.type === "dragleave") {
            this.setState({dragActive: false})
        }
    }

    handleDrop (e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({dragActive: false})
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            // at least one file has been dropped so do something
            // handleFiles(e.dataTransfer.files);
            // console.log(e.dataTransfer.files)
            let files = e.dataTransfer.files

            let previewList = []
            for(let i=0; i < files.length; i++) {
                let selectedFile = files[i];
                let fileName = selectedFile.name;
                let fileSize = selectedFile.size;
                if(fileSize > MAX_FILE_SIZE) {
                    const fileMB = Math.round((fileSize / MB_SIZE));
                    AlertError('ไฟล์ '+fileName+' มีขนาด '+fileMB+ ' Mb มากกว่า '+MAX_FILE_SIZE_LIMIT+' Mb ไม่สามารถอัพโหลดได้')
                    previewList = []
                    break;
                }

                const objectUrl = URL.createObjectURL(selectedFile)
                previewList.push(objectUrl)
            }

            if(previewList.length !== 0) {
                this.setState({
                    fileList: files,
                    previewList: previewList
                });
            }
        }
    };

    /*handleSubmit (event) {
        event.preventDefault();

        let car_doc_name = event.target.car_doc_name.value

        if(car_doc_name===''){
            this.setState({
                w_car_doc_name: true
            });
            return;
        }

        try {
            if(event.target.car_doc_file.files[0].name === undefined){
                this.setState({
                    w_car_doc_file: false
                });
            }
        }catch (ex){
            this.setState({
                w_car_doc_file: true
            });
            return;
        }

        var filename = event.target.car_doc_file.files[0].name ;

        if(!(/\.(gif|jpg|jpeg|tiff|png|pdf)$/i).test(filename)){
            //alert(event.target.car_repair_file.files[0].type);
            this.setState({
                w_car_repair_file: true
            });
            return;
        }

        this.props.onCarDocSubmit.add(event)

    } */

    getDocTypeName() {
        let car_doc_type = this.props.car_doc_type
        return docUtil.getCarDocTypeName(car_doc_type)
    }

    getPreviewImage() {
        let previewList = this.state.previewList

       /* let imgList = []
        for(let i=0 ; i< previewList.length; i++) {
            let url = previewList[i]
            if(i % 5 === 0 && i !== 0) {
                imgList.push(<br key={'x'+i} />)
            }
            imgList.push(<img key={i} src={url} width={50} style={{marginRight: 6}} />)
        }*/

         let imgList = previewList.map((url, index) => {

            /*if(index % 5 === 0 && index !== 0) {
                return  <div key={index}> <br/> <img  src={url} width={50} style={{marginRight: 6}} /> </div>
            }*/
            if(index % 5 === 0) {
                return <React.Fragment key={index}>  <div className="col-1">&nbsp;</div>  <div key={index} className="col-2"> <img  src={url} width={64} /> </div> </React.Fragment>
            }

             if(index % 5 === 4) {
                 return <React.Fragment key={index}>  <div key={index} className="col-2"> <img  src={url} width={64} /> </div> <div className="col-1">&nbsp;</div> </React.Fragment>
             }

            return <div key={index} className="col-2"> <img  src={url} width={64} /> </div>

        });

        return imgList
    }

    async uploadFiles() {
        const {
            fileList,
            car_doc_name
        } = this.state

        if(fileList.length === 0) {
            AlertError('กรุณาเลือกไฟล์')
            return;
        }

        const car = this.props.car
        const car_id = car.car_id
        const car_doc_type = this.props.car_doc_type
        const username = this.props.username
        const doc_upload_code = this.state.doc_upload_code
        const upload_type = 'multiple'
        // console.log('---uploadFiles--- car_id=', car_id, car_doc_type)
        // console.log(fileList)
        // this.setState(prevState => ({
        //     jasper: {                   // object that we want to update
        //         ...prevState.jasper,    // keep all other key-value pairs
        //         name: 'something'       // update the value of specific key
        //     }
        // }))

        if(car_doc_name===''){
            AlertWarning('กรุณากรอกชื่อเอกสาร');
            this.setState({
                w_car_doc_name: true
            });
            return;
        }

        this.setState( prevState => ({
            isLoading: true,
            progress: {...prevState.progress, start: true}
        }))

        // loop uploads
        let allSize = fileList.length
        for(let i=0; i < allSize; i++) {
            let selectedFile = fileList[i];
            let fileName = selectedFile.name;

            let data = {
                file: selectedFile,
                fileName: fileName,
                car_id: car_id,
                car_doc_name: car_doc_name,
                car_doc_type: car_doc_type,
                username: username
            }
            //console.log(i, data)
            let numFile = i + 1
            let currentPercent = parseInt(((100 * numFile)/allSize))

            const formData = new FormData();
            formData.append('file', selectedFile , fileName );
            formData.append('car_id', car_id);
            formData.append('car_doc_name', car_doc_name);
            formData.append('car_doc_type', car_doc_type);
            formData.append('user_created', username);
            formData.append('doc_upload_code', doc_upload_code);
            formData.append('upload_type', upload_type);

            console.log('---------------')
            let result = await axios.post(APIURL+'/car_doc2', formData, {
                onUploadProgress: (progressEvent) => {
                    console.log('progressEvent.progress: ', progressEvent.progress, ' | ',currentPercent)
                }
            })

            console.log(i,result.data)
            console.log(i, currentPercent)

            this.setState( prevState => ({
                progress: {...prevState.progress, pc: currentPercent},
                isLoading: false,
            }))

            if (i === (allSize - 1) && currentPercent === 100) {
                AlertSuccess('อัพโหลดสำเร็จแล้ว')
                this.props.onToggle()
                this.props.onReload()
            }


            /*( async function () {

                let data = {
                    file: selectedFile,
                    fileName: fileName,
                    car_id: car_id,
                    car_doc_name: car_doc_name,
                    car_doc_type: car_doc_type,
                    username: username
                }
                //console.log(i, data)
                let numFile = i + 1
                let currentPercent = parseInt(((100 * numFile)/allSize))

                const formData = new FormData();
                formData.append('file', selectedFile , fileName );
                formData.append('car_id', car_id);
                formData.append('car_doc_name', car_doc_name);
                formData.append('car_doc_type', car_doc_type);
                formData.append('user_created', username);

                    await  axios.post(APIURL+'/car_doc2',data)
                    .then(res => {
                        console.log('---------------')
                        console.log(i,res.data)
                        console.log(i, currentPercent)

                    })
                    .catch(error => {
                        console.log('error:', error);
                    })

            }())*/
        }

    }

    render(){
        const {
            dragActive,
            previewList
        } = this.state

        return(
            <>
            <Loading isLoading={this.state.isLoading} />

            <div className="card card-accent-primary ">
                <div className="card-header">
                    <strong className="text-title">
                        {this.getDocTypeName()}
                    </strong>
                </div>


                    <div className="card-block">


                        <div className="row">
                            <div className="form-group col-md-12">
                                <label htmlFor="center_name">ชื่อเอกสาร</label>
                                <input type="text"
                                       className="form-control"
                                       name="car_doc_name"
                                       id="car_doc_name"
                                       placeholder="ชื่อเอกสาร"
                                       value={this.state.car_doc_name}
                                       onChange={(e) => this.setState({car_doc_name: e.target.value})}
                                />
                                {this.state.w_car_doc_name ? <span  className="help-block text-danger">กรุณากรอกชื่อเอกสาร</span>: null }
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-md-12 text-center ">
                                <form id="form-file-upload"
                                      className="m-auto"
                                      onDragEnter={this.handleDrag} onSubmit={(e) => e.preventDefault()}
                                >
                                    <input
                                        ref={this.inputRef}
                                        accept="image/*, application/pdf"
                                        type="file" id="input-file-upload" multiple={true} onChange={this.handleChange} />

                                    <div id="label-file-upload"
                                           htmlFor="input-file-upload"
                                           className={dragActive ? "drag-active" : "" }
                                           onClick={this.onButtonClick}
                                    >
                                        <div className="flex-item" style={{width: '100%'}} >
                                            <p>ลากไฟล์วางตรงนี้ หรือกดเพื่อเลือกไฟล์</p>
                                            <button className="upload-button" onClick={this.onButtonClick} />
                                        </div>

                                        <div className="flex-item" style={{width: '100%'}}>
                                            <div className="row">

                                                {previewList.length > 0 && this.getPreviewImage()}

                                            </div>
                                        </div>
                                    </div>
                                    { dragActive && <div id="drag-file-element"
                                                         onDragEnter={this.handleDrag}
                                                         onDragLeave={this.handleDrag}
                                                         onDragOver={this.handleDrag}
                                                         onDrop={this.handleDrop}></div> }

                                </form>

                                <Progress className="progress-xs mt-2"
                                          color="primary"
                                          value={this.state.progress.pc}
                                />

                            </div>
                        </div>



                        {/*<div className="row">
                            <div className="form-group col-md-12">
                                <label htmlFor="car_repair_file">ไฟล์เอกสาร (ภาพ, pdf)</label>
                                <input type="file"
                                       id="car_doc_file"
                                       name="car_doc_file"
                                       accept="image/*, application/pdf"
                                       className="form-control"
                                       onChange={(e)=>{
                                           const { target } = e
                                           if(target.value.length > 0){
                                               this.setState({
                                                   w_car_doc_file: false
                                               });
                                           } else {
                                               this.setState({
                                                   w_car_doc_file: true
                                               });
                                           }

                                       }}
                                />
                                { this.state.w_car_doc_file ? <span  className="help-block text-danger">กรุณาเลือกไฟล์ ประเภท ( .jpg  .jpeg .png )</span>: null }

                            </div>
                        </div>*/}

                    </div>
                    <div className="card-footer text-right">
                        <button type="reset"
                                onClick={this.props.onCarDocSubmit.cancel}
                                className="btn btn-sm btn-danger mr-2">
                            <i className="fa fa-refresh"/> ยกเลิก
                        </button>
                        <button type="button"
                                onClick={this.uploadFiles}
                                className="btn btn-sm btn-primary">
                            <i className="fa fa-save"/> บันทึก
                        </button>

                        <input type="hidden" id="car_id" name="car_id" value={this.state.car.car_id}/>

                    </div>


            </div>
                </>
        )
    }
}

// อัพโหลดไฟล์

export default  CarDocAddMulti;
