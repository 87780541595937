import React, { Component } from 'react';
import Loading from '../Loading';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import axios from 'axios';
// import * as XLSX from "xlsx-js-style";
// import * as FileSaver from "file-saver";
import {APIURL, PORT} from '../../config/config';
import { save } from 'save-file';
import { AlertWarning } from '../Alert/Alert'
import {ExcelUtil} from "../../utils/excelUtil";
import {format} from "date-fns";
import {NumberUtil} from "../../utils/number-util";

// const ExcelJS = require("exceljs");
// import ExcelJS from 'exceljs';

/*const toDataURL = (url) => {
  const promise = new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.onload = function () {
      let reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });

  return promise;
};*/


class CarbookReport extends Component{
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      date_start: new Date(),
      date_end: new Date(),
      data_by: 'byDate', // all, byDate
      lists:[],
      branches: [],
      branch_id: ''
    }

    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd = this.handleDateEnd.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.loadBranchData = this.loadBranchData.bind(this)
    this.loadCarBookData = this.loadCarBookData.bind(this)
    this.exportCarBookData = this.exportCarBookData.bind(this)
    this.exportCarBookDataFromClient = this.exportCarBookDataFromClient.bind(this)

  }

  componentDidMount () {
    this.loadBranchData()
  }

  handleOnChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  loadBranchData () {
    axios.get(APIURL + '/branch/')
      .then(res => {
        this.setState({branches: res.data}, () => this.loadCarBookData())
      }).catch(error => {
      this.setState({branches: []})
    })
  }

  loadCarBookData() {

    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    const data_by = this.state.data_by
    const branch_id = this.state.branch_id

    const data = {
      start_date: start_date,
      end_date: end_date,
      data_by: data_by,
      branch_id: branch_id
    }

    this.setState({
      isLoading: true
    }, () => {
      axios.post(APIURL + '/report/carbook', data)
        .then(res => {
          this.setState({
            isLoading: false,
            lists: res.data
          }, () => {
            // this.showBranchSummary()
          })

        }).catch(error => {
        console.log('error:', error)
      })
    })
    // axios.get(APIURL + '/report/carin/'+start_date+'/'+end_date)

  }

  async exportCarBookData() {

    if(this.state.date_start > this.state.date_end){
      alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    const data_by = this.state.data_by
    const branch_id = this.state.branch_id

    const data = {
      start_date: start_date,
      end_date: end_date,
      data_by: data_by,
      branch_id: branch_id
    }

    this.setState({
      isLoading: true
    }, () => {
      axios.post(APIURL + '/report/carbookexcel', data)
        .then(async res => {

          const excelBuffer = res.data.excelBuffer
          const currentDatetime = res.data.currentDatetime
          const fileName = 'รายงานการจอง_export_'+currentDatetime+'.xlsx'

          this.setState({
            isLoading: false
          })

          await save(excelBuffer, fileName)

        }).catch(error => {
        console.log('error carinexport:', error)
      })
    })

  }

  async exportCarBookDataFromClient() {
    let lists = this.state.lists
    if(lists.length === 0) {
      AlertWarning('ไม่พบข้อมูล กรุณากดตกลงเพื่อค้นหาข้อมูล');
      return
    }

    this.setState({
      isLoading: true
    })
    // const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    // const fileExtension = '.xlsx';

    let excelData = lists.map((car, index) => {

      const car_license_plate = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ? car.car_license_plate_new : car.car_license_plate_old
      const province_name = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ? car.province_name_new : car.province_name_old

      let car_license = car_license_plate+' '+province_name

      let car_book_status = '';
      if (car.car_book_status_id === 1) { // 1 = จอง 2= ยกเลิก
        car_book_status = 'จอง'
      } else {
        car_book_status = 'ยกเลิก'
      }

      let car_book_date = moment(car.car_book_date).format('YYYY-MM-DD')

      //  car_name: car.car_name,
      //  car_status_name: car.car_status_name,
      let customer_fullname = car.customer_name +' '+car.customer_lastname
      let book_comment = car.book_comment === null ? '' : car.book_comment
      let book_comment_refund = car.book_comment_refund === null ? '' : car.book_comment_refund
      let car_sale_price = NumberUtil.addCommas(car.car_sale_price);
      let cost_car_book = NumberUtil.addCommas(car.cost_car_book);
      let cost_car_finance = NumberUtil.addCommas(car.cost_car_finance);
      let cost_down = NumberUtil.addCommas(car.cost_down);
      let cost_pledge = NumberUtil.addCommas(car.cost_pledge);
      let car_sell_pay_count = NumberUtil.addCommas(car.car_sell_pay_count);

      // delete car.car_sale_price

      return {
        ...car,
        car_sale_price: car_sale_price,
        cost_car_book: cost_car_book,
        cost_car_finance: cost_car_finance,
        cost_down: cost_down,
        cost_pledge: cost_pledge,
        car_sell_pay_count: car_sell_pay_count,
        //
        index: index+1,
        thumbnail: car.car_upload_filename,
        car_book_status: car_book_status,
        car_book_date: car_book_date,
        car_license: car_license,
        customer_fullname: customer_fullname,
        book_comment: book_comment,
        book_comment_refund: book_comment_refund
      }
    });


    // const ExcelJS = require("exceljs");
    const ExcelJS = require("exceljs/dist/exceljs");

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("ข้อมูลการจอง");

    // const sheet = workbook.addWorksheet('My Sheet', {
    //   headerFooter:{firstHeader: "Hello Exceljs", firstFooter: "Hello World"}
    // });

    // sheet.properties.defaultRowHeight = 100;

    /*sheet.getRow(1).border = {
      top: { style: "thick", color: { argb: "FFFF0000" } },
      left: { style: "thick", color: { argb: "000000FF" } },
      bottom: { style: "thick", color: { argb: "F08080" } },
      right: { style: "thick", color: { argb: "FF00FF00" } },
    };

    sheet.getRow(1).fill = {
      type: "pattern",
      pattern: "darkVertical",
      fgColor: { argb: "FFFF00" },
    };

    sheet.getRow(1).font = {
      name: "AngsanaUPC",
      family: 4,
      size: 18,
      bold: true,
    };*/

    let titleStyle = {
      name: "AngsanaUPC",
      family: 4,
      size: 18,
      bold: true,
    };

    let contentStyle = {
      name: "AngsanaUPC",
      family: 4,
      size: 16,
      bold: false,
    };

    let contentBoldStyle = {
      name: "AngsanaUPC",
      family: 4,
      size: 16,
      bold: true,
    };

    let border= {
      top: {style:'thin'},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    }


    sheet.columns = [
      { header: "ลำดับ", key: "index", width: 6,},

      { header: "รูปรถ", key: "thumbnail", width: 16,},
      { header: "วันที่จอง", key: "car_book_date", width: 15,},
      { header: "ชื่อรถ", key: "car_name", width: 32,},
      { header: "สถานะรถ", key: "car_status_name", width: 12,},

      { header: "ทะเบียน", key: "car_license", width: 24,},
      { header: "ราคาขาย", key: "car_sale_price", width: 12,},
      { header: "ราคาจอง", key: "cost_car_book", width: 12,},
      { header: "ยอดจัด", key: "cost_car_finance", width: 12,},

      { header: "ดาวน์", key: "cost_down", width: 12, },
      { header: "เงินมัดจำ", key: "cost_pledge", width: 12, },
      { header: "จำนวนเงินจอง", key: "car_sell_pay_count", width: 12, },
      { header: "ผู้รับเงิน", key: "user_receive_money", width: 12, },

      { header: "ชื่อลูกค้า", key: "customer_fullname", width: 20, },
      { header: "เบอร์โทร", key: "customer_mobile", width: 12, },
      { header: "ประกันรถ", key: "insurance_type_name", width: 12, },
      { header: "ผู้รับจอง(เซลล์)", key: "booking_by", width: 12, },

      { header: "ช่องทางลูกค้า", key: "profile_from", width: 12, },
      { header: "สถานะการจอง", key: "car_book_status", width: 12, },
      { header: "หมายเหตุการจอง", key: "book_comment", width: 20, },
      { header: "หมายเหตุการคืนจอง", key: "book_comment_refund", width: 20, },

    ]


    /*sheet.addTable({
      name: 'MyTable',
      ref: 'A4',
      headerRow: true,
      totalsRow: false,
     /!* style: {
        theme: 'TableStyleDark3',
        showRowStripes: true,
      },*!/
      /!*columns: [
        {name: 'Date', totalsRowLabel: 'Totals:', filterButton: false},
        {name: 'Amount', totalsRowFunction: 'sum', filterButton: false},
      ],*!/
      columns: columns,
      rows: excelData,
    });*/

    const printDate = moment().locale('th').format('DD MMM YYYY') + ' ' + moment().format('hh:mm:ss')

    let start_date =  moment(this.state.date_start).locale('th').format('DD MMM YYYY')
    let end_date   =  moment(this.state.date_end).locale('th').format('DD MMM YYYY')

    let rows = [
      ['รายงานการจอง'],
      ['พิมพ์วันที่ : '+printDate],
      ['ข้อมูลวันที่ : '+start_date+' ถึง '+end_date]
    ]

    sheet.insertRows(1, rows);

    const promise = Promise.all(
        excelData.map(async (car, index) => {
         let rowNumber = index + 4;

          delete car.thumbnail
          sheet.addRow(car)

          const result = await ExcelUtil.toDataURL(car.car_upload_filename);
          const splitted = car.car_upload_filename.split(".");
          const extName = splitted[splitted.length - 1];

          const imageId2 = workbook.addImage({
            base64: result.base64Url,
            extension: extName,
          });

          sheet.getRow(rowNumber).eachCell((cell, colNumber ) =>  {
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
            cell.border = border;
          });

          if (rowNumber === 4) {
            sheet.getRow(rowNumber).font = contentBoldStyle
          } else {
            sheet.getRow(rowNumber).font = contentStyle
          }

          sheet.getRow(rowNumber+1).height = 80

          sheet.addImage(imageId2, {
            tl: { col: 1.1, row: rowNumber+0.1 },
            ext: { width: 96, height: 96 },
          });

          if((excelData.length-1) === index) {
            // set last row
            rowNumber = rowNumber +1
            sheet.getRow(rowNumber).eachCell((cell, colNumber ) =>  {
              cell.alignment = { vertical: 'middle', horizontal: 'center' };
              cell.border = border;
            });
            sheet.getRow(rowNumber).font = contentStyle;
          }
        })
    );

    promise.then(() => {

      sheet.getRow(1).font = titleStyle
      sheet.getRow(2).font = contentStyle
      sheet.getRow(3).font = contentStyle
      sheet.getRow(4).font = contentBoldStyle

      // B5
      for(let i=0; i < excelData.length; i++) {
        let row = i + 5
        let cellName = 'B'+String(row)
        sheet.getCell(cellName).border = border
        /*sheet.getCell(cellName).margins  = {
          insetmode: 'custom',
          inset: [0.25, 0.25, 0.35, 0.35]
        }*/
      }

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        let curr = format(new Date(), 'yyyyMMdd_HHmmss')
        const fileName = 'รายงานการจอง_export_'+curr+'.xlsx'

        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });

      setTimeout(() => {
        this.setState({
          isLoading: false
        })
      } , 1200)

    });

  }

  render () {
    const {
      lists
    } = this.state

    let car_branch_list = this.state.branches.map((branch, index) => {
      return (
        <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
      )
    })

    const emptyBody = <tr><td colSpan={20} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

    const listRows = lists.map((car, index) => {

      let car_book_date = moment(car.car_book_date).format('YYYY-MM-DD')

      //let car_license_plate = car.car_license_plate_new === '' ? car.car_license_plate_old : car.car_license_plate_new

      const car_license_plate = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ?
          car.car_license_plate_new : car.car_license_plate_old

      const province_name = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ?
          car.province_name_new : car.province_name_old

      let car_book_status = '';
      if (car.car_book_status_id === 1) { // 1 = จอง 2= ยกเลิก
        car_book_status = 'จอง'
      } else {
        car_book_status = 'ยกเลิก'
      }

      let book_comment = car.book_comment === null ? '' : car.book_comment
      let book_comment_refund = car.book_comment_refund === null ? '' : car.book_comment_refund
      let employee_book = car.employee_book_id === 0 || car.employee_book_id === '' ? 'ไม่ระบุ' : car.employee_book_name + ' ' + car.employee_book_lastname
      let booking_by = car.booking_by === null || car.booking_by === '' ? employee_book : car.booking_by

      return (
        <tr key={index}>
          <td className="text-center">{index+1}</td>
          <td className="text-center text-nowrap">{car_book_date}</td>
          <td className="text-left text-nowrap">{car.car_name}</td>
          <td className="text-center">{car.car_status_name}</td>
          <td className="text-center text-nowrap">{car_license_plate}{' '}{province_name}</td>
          <td className="text-center text-nowrap">{car.car_sale_price.toLocaleString()}</td>
          <td className="text-center text-nowrap">{car.cost_car_book.toLocaleString()}</td>
          <td className="text-center text-nowrap">{car.cost_car_finance.toLocaleString()}</td>

          <td className="text-center text-nowrap">{car.cost_down.toLocaleString()}</td>
          <td className="text-center text-nowrap">{car.cost_pledge.toLocaleString()}</td>
          <td className="text-center text-nowrap">{car.car_sell_pay_count.toLocaleString()}</td>
          <td className="text-center text-nowrap">{car.user_receive_money}</td>

          <td className="text-center text-nowrap">{car.customer_name}{' '}{car.customer_lastname}</td>
          <td className="text-center text-nowrap">{car.customer_mobile}</td>
          <td className="text-center text-nowrap">{car.insurance_type_name}</td>
          {/*<td className="text-center text-nowrap">{car.user_book}</td>*/}
          <td className="text-center text-nowrap">{booking_by}</td>

          <td className="text-center text-nowrap">{car.profile_from}</td>
          <td className="text-center text-nowrap">{car_book_status}</td>
          <td className="text-center text-nowrap">{book_comment}</td>
          <td className="text-center text-nowrap">{book_comment_refund}</td>
        </tr>
      )
    })

    const tableRows = lists.length === 0 ? emptyBody : listRows

    return (
      <div>
        <Loading isLoading={this.state.isLoading} />
        <div className="card-block" style={styles.overflowY}>
          <div className="row">
            <div className="card card-accent-primary">
              <div className="card-header">
                <div className="row col-12 mt-1">
                  <div className="col-6">
                    <strong className="text-title">รายงานการจอง</strong>
                  </div>
                  <div className="col-3">

                  </div>

                  {
                    this.state.lists.length === 0 ? '': (
                      <div className="col-3 text-center pt-2 pb-1 bg-primary">
                        <h5>
                          พบ&nbsp; {this.state.lists.length} &nbsp;รายการ
                        </h5>
                      </div>
                    )
                  }

                </div>
              </div>

              <div className="card-block">

                <div className="row mt-1">
                  <div className="mt-1 ml-4">
                    จาก
                  </div>

                  <div className="form-group col-1 ml-2">
                    <DatePicker
                      selected={this.state.date_start}
                      onChange={this.handleDateStart}
                      dateFormat="yyyy-MM-dd"
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                  {/* <div className="col-1 text-center">
                      ถึง
                    </div>*/}
                  <div className="form-group col-1">
                    <DatePicker
                      selected={this.state.date_end}
                      onChange={this.handleDateEnd}
                      dateFormat="yyyy-MM-dd"
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>

                  <div className="form-group col-1">
                    <select className="form-control" id="branch_id" name="branch_id"
                            onChange={(e) => {
                              this.setState({
                                branch_id: e.target.value
                              })
                            }}
                    >
                      <option value=''> ตามสาขา </option>
                      {car_branch_list}
                    </select>
                  </div>

                  <div className="form-group mt-1 ml-3">
                    <input type="radio"
                           name="data_by"
                           value="byDate"
                           className="btn btn-primary"
                           checked={this.state.data_by === 'byDate'}
                           onChange={this.handleOnChange}
                    /> &nbsp;<span style={{marginTop: -4}}>ตามวันที่</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="radio"
                           name="data_by"
                           value="all"
                           className="btn btn-primary"
                           checked={this.state.data_by === 'all'}
                           onChange={this.handleOnChange}
                    /> &nbsp;<span style={{marginTop: -4}}>ทั้งหมด</span>
                  </div>

                  <div className="col-2 ml-4 mr-1">
                    <button type="button" style={{width: 80}}
                            className="btn btn-sm btn-primary"
                            onClick={() => this.loadCarBookData()} > ตกลง
                    </button>
                    &nbsp;&nbsp;
                    <button type="button" style={{width: 80}}
                            className="btn btn-sm btn-success"
                            onClick={() => {
                              if(PORT === 9008) {
                                this.exportCarBookDataFromClient()
                              } else {
                                this.exportCarBookData()
                              }
                            }} >
                      Excel
                    </button>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-12">

                    <div>
                      <span className="text-danger">***แสดงทุกรายการที่มีการจอง บางคันมีการจองมากกว่า 1 รายการ</span>
                    </div>
                    <table className="table table-striped table-responsive">
                      <thead>
                      <tr>
                        <th style={{width: 10}} className="text-center text-nowrap">ลำดับ</th>
                        <th style={{width: 60}} className="text-center text-nowrap">วันที่จอง</th>
                        <th style={{width: 150}} className="text-center text-nowrap">ชื่อรถ</th>
                        <th style={{width: 150}} className="text-center text-nowrap">สถานะรถ</th>
                        <th style={{width: 50}} className="text-center text-nowrap">ทะเบียน</th>
                        <th style={{width: 50}} className="text-center text-nowrap">ราคาขาย</th>
                        <th style={{width: 50}} className="text-center text-nowrap">ราคาจอง</th>
                        <th style={{width: 50}} className="text-center text-nowrap">ยอดจัด</th>

                        <th style={{width: 50}} className="text-center text-nowrap">ดาวน์</th>
                        <th style={{width: 50}} className="text-center text-nowrap">เงินมัดจำ</th>
                        <th style={{width: 50}} className="text-center text-nowrap">จำนวนเงินจอง</th>
                        <th style={{width: 50}} className="text-center text-nowrap">ผู้รับเงิน</th>

                        <th style={{width: 50}} className="text-center">ชื่อลูกค้า</th>
                        <th style={{width: 50}} className="text-center text-nowrap">เบอร์โทร</th>
                        <th style={{width: 50}} className="text-center text-nowrap">ประกันรถ</th>
                        <th style={{width: 50}} className="text-center text-nowrap">ผู้รับจอง(เซลล์)</th>

                        <th style={{width: 50}} className="text-center text-nowrap">ช่องทางลูกค้า</th>
                        <th style={{width: 50}} className="text-center text-nowrap">สถานะการจอง</th>
                        <th style={{width: 50}} className="text-center text-nowrap">หมายเหตุการจอง</th>
                        <th style={{width: 50}} className="text-center text-nowrap">หมายเหตุการคืนจอง</th>
                      </tr>
                      </thead>
                      <tbody>
                       {tableRows}
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

}

const styles = {
  overflowY: {
    overflow: 'scroll',
  }
}

export default CarbookReport
