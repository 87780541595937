import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CarEditStatus from "../CarEditStatus/CarEditStatus"
import axios from 'axios'
import {APIURL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL, IMAGE_THUMB_URL, PAGE10} from '../../../config/config'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap'
import * as moment from 'moment';
import CarCalculate from '../../../components/Cars/CarCalculate'
import CarShare from '../../../components/Cars/CarShare'
import { connect } from 'react-redux'
import CarLeasingForm from '../CarLeasingForm'
import CarRowReservation from '../CarRowReservation'
import CarReleaseForm from '../CarReleaseForm'
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import Loading from '../../Loading'
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";
import {checkGroupPermission} from "../../../services/grouppermission";
import CarBookChangeCar from "./CarBookChangeCar";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew-Regular.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

class CarReservation extends Component {
  constructor (props) {
    super(props)

    this.state = {
      lists: [],
      user: {},
      user_type: '',
      car_id: 0,
      action: 'บันทึก',
      car: '',
      car_edit_status: {},
      toggle_car_status_edit: false,
      toggle_car_calculate: false,
      toggle_car_share: false,
      car_calculate: {},
      car_share: {},
      count_car: 0,
      car_select: false,
      is_search: false,
      car_delete: {},
      toggle_car_status_delete: false,
      toggle_car_status_leasing: false,
      carLeasingList: [],
      car_release: {},
      toggle_car_release: false,
      isLoading: false,
      width: 0,
      height: 0,
      carBase64Img: '',
      toggle_change_car: false,
      change_car: {},
    }
    this.onCarStatusEdit = this.onCarStatusEdit.bind(this)
    this.toggleCarStatusEdit = this.toggleCarStatusEdit.bind(this)
    this.onCarStatusChange = this.onCarStatusChange.bind(this)
    this.toggleCarCalculate= this.toggleCarCalculate.bind(this);
    this.onCarCalculate = this.onCarCalculate.bind(this);
    this.onCarShare = this.onCarShare.bind(this);
    this.toggleCarShare = this.toggleCarShare.bind(this)
    this.onCarShareChange = this.onCarShareChange.bind(this)
    this.onRefreshCar = this.onRefreshCar.bind(this);
    this.toggleCarDelete = this.toggleCarDelete.bind(this);
    this.onCarDelete = this.onCarDelete.bind(this);
    this.toggleCarLeasing = this.toggleCarLeasing.bind(this);
    this.onCarLeasing = this.onCarLeasing.bind(this);
    this.onSubmitCarLeasing = this.onSubmitCarLeasing.bind(this);

    this.onCarRelease = this.onCarRelease.bind(this);
    this.toggleCarRelease = this.toggleCarRelease.bind(this);
    this.onSubmitCarRelease = this.onSubmitCarRelease.bind(this);
    this.loadCarImageBase64 = this.loadCarImageBase64.bind(this);

    this.printClaim = this.printClaim.bind(this);
    this.loadCarData = this.loadCarData.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.onChangeCarBook = this.onChangeCarBook.bind(this);
    this.onCarStatusChange = this.onCarStatusChange.bind(this);

    this._isMounted = false;
  }

  componentWillMount () {
    // const queryString = require('query-string');
    let user = JSON.parse(this.props.user);
    this.setState({
      user: user ,
      user_type : user.type
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  loadCarData (page) {

    let username = this.state.user.user
    let uri = APIURL + '/car_reservation/page/'+page+'/'+username;

    this.setState({
      isLoading: true
    }, () => {
      axios.get(uri)
        .then(res => {
          let carReservation = res.data;

          this._isMounted &&  this.setState({
            lists: carReservation,
            carLeasingList: [],
            isLoading: false
          })
        }).catch(error => {
        this._isMounted && this.setState({lists: []})
      })
    })
  }

  loadCountCarData() {
    let  uri = APIURL + '/car_reservation/getcount/count'
    axios.get(uri)
      .then(res => {
        //set state
        this._isMounted && this.setState({
          count_car: res.data[0].count_car
        });
      }).catch(error => {
         console.log(error)
      })
  }

  componentDidMount () {
    CarsUtil.removeCarSelect()
    CarSellUtil.removeCarSellSelect()
    // if(this.state.load_data)

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    this._isMounted = true;
    this._isMounted && this.loadCarData(1)
    this._isMounted && this.loadCountCarData()

  }

  onCarStatusEdit (car) {
    this.setState({car_edit_status: car},()=>{
      this.toggleCarStatusEdit()
    })
  }

  toggleCarStatusEdit(){
    this.setState({toggle_car_status_edit: !this.state.toggle_car_status_edit})
  }

  onCarStatusChange(car_id, car_status_id){
    //alert("car_id="+car_id+" , car_status_id="+car_status_id)
    axios.put(APIURL + '/car/car_status/'+car_id,{
      car_id: car_id,
      car_status_id: car_status_id,
    })
      .then(res => {
        this.loadCarData()
        this.toggleCarStatusEdit()
      }).catch(error => {
    })
  }

  toggleCarCalculate() {
    this.setState({
      toggle_car_calculate: !this.state.toggle_car_calculate
    });
  }

  toggleCarDelete() {
    this.setState({
      toggle_car_status_delete: !this.state.toggle_car_status_delete
    });
  }

  toggleCarLeasing() {
    this.setState({
      toggle_car_status_leasing: !this.state.toggle_car_status_leasing
    });
  }

  toggleCarRelease() {
    this.setState({
      toggle_car_release: !this.state.toggle_car_release
    });
  }

  toggleCarShare() {
    this.setState({
      toggle_car_share: !this.state.toggle_car_share
    },()=>{
      if(this.state.toggle_car_share===false){
        this.loadCarData(1);
        this.loadCountCarData();
      }
    });
  }

  onCarCalculate(car){
    this.setState({car_calculate: car},()=>{
      this.toggleCarCalculate()
    })
  }
  onCarShare(car){
    this.setState({car_share: car},()=>{
      this.toggleCarShare()
    })
  }

  onCarDelete(car) {
    this.setState({car_delete: car},()=>{
      this.toggleCarDelete()
    })
  }

  onCarLeasing(car) {
    this.setState({car_leasing: car},()=>{
      this.toggleCarLeasing()
    })
  }

  onCarRelease(car){
    this.setState({car_release: car},()=>{
      this.toggleCarRelease()
    })
  }

  onCarShareChange(car_id){
    //alert("onCarShareChange car.car_id="+car_id)
  }

  searchCar () {
    let search_text = this.refs.search_car_name.value
    if (search_text !== ""){
      search_text = String(search_text).trim()
      this.setState ({is_search: true})
      this.getDataCarSearch(search_text)
    }else {
      this.setState ({is_search: false},()=>{
        this.loadCarData(1)
      })
    }
  }

  getDataCarSearch (search_text) {


    if(search_text.length < 3) {
      return
    }

    let username = this.state.user.user

    let uri = '';
    if(this.state.user_type === 'admin'){
      uri = APIURL + '/car_reservation/search/' + search_text+'/'+username;
    }else if(this.state.user_type === 'sale'){
      // uri = APIURL + '/carsale/search/'+search_text;
      uri = APIURL + '/car_reservation/search/' + search_text+'/'+username;
    }else if(this.state.user_type === 'technician'){
      uri = APIURL + '/cartechnician/search/'+search_text;
    }

    this.setState({
      isLoading: true
    }, () => {
      axios.get(uri)
        .then(res => {
          let data = res.data;
          let carReservation = data.filter((item) => item.car_status_id === 7);
          this.setState({
            lists: carReservation,
            isLoading: false
          });
        }).catch(error => {
        this.setState({lists: []})
      })
    })


  }

  onRefreshCar(){
    this.refs.search_car_name.value = ''
    this.setState ({is_search: false},()=>{
      this.loadCarData(1)
    })
  }

  carDelete(car_id) {
    if(this.state.user_type === 'admin'){
      axios.delete(APIURL + '/car/' + car_id).then(res => {
        if (res.status === 200) {
          // this.setState({action: "บันทึก"});
          this.toggleCarDelete()
          this.loadCarData(1)
          this.loadCountCarData()
        }
      })
    }
  }

  onSubmitCarLeasing(e) {
    e.preventDefault();
    /* e.target.map(name => {

     })*/
    // var leasingName = ['SCB', 'GE', 'TA', 'KK', 'TIS'];
    let action = e.target.action.value;

    let leasing_SCBM = e.target.SCBM.value ===''? 0 : e.target.SCBM.value;
    let leasing_GEM =  e.target.GEM.value ===''? 0 : e.target.GEM.value;
    let leasing_TAM =  e.target.TAM.value ===''? 0 : e.target.TAM.value;
    let leasing_KKM =  e.target.KKM.value ===''? 0 : e.target.KKM.value;
    let leasing_TISM = e.target.TISM.value ===''? 0 : e.target.TISM.value;

    let leasing_SCB = e.target.SCB.value ===''? 0 : e.target.SCB.value;
    let leasing_GE =  e.target.GE.value ===''? 0 : e.target.GE.value;
    let leasing_TA =  e.target.TA.value ===''? 0 : e.target.TA.value;
    let leasing_KK =  e.target.KK.value ===''? 0 : e.target.KK.value;
    let leasing_TIS = e.target.TIS.value ===''? 0 : e.target.TIS.value;

    let leasing_SCB2 = e.target.SCB2.value ===''? 0 : e.target.SCB2.value;
    let leasing_GE2 =  e.target.GE2.value ===''? 0 : e.target.GE2.value;
    let leasing_TA2 =  e.target.TA2.value ===''? 0 : e.target.TA2.value;
    let leasing_KK2 =  e.target.KK2.value ===''? 0 : e.target.KK2.value;
    let leasing_TIS2 = e.target.TIS2.value ===''? 0 : e.target.TIS2.value;

    // var leasing_MPRICE = e.target.MPRICE.value ===''? 0 : e.target.MPRICE.value;
    let data = {
      'SCBM'  : leasing_SCBM,
      'GEM'   : leasing_GEM,
      'TAM'   : leasing_TAM,
      'KKM'   : leasing_KKM,
      'TISM'  : leasing_TISM,

      'SCB'  : leasing_SCB,
      'GE'   : leasing_GE,
      'TA'   : leasing_TA,
      'KK'   : leasing_KK,
      'TIS'  : leasing_TIS,

      'SCB2'  : leasing_SCB2,
      'GE2'   : leasing_GE2,
      'TA2'   : leasing_TA2,
      'KK2'   : leasing_KK2,
      'TIS2'  : leasing_TIS2,
      //'MPRICE'  : leasing_MPRICE,
      'user' : this.state.user.user
    }

    if(action==='add') {
      axios.post(APIURL + '/car_leasing/' + this.state.car_leasing.car_id, data)
        .then((res) => {
          if (res.status === 200) {
            //this.toggleModal();
            //this.loadCarClaim();
            this.toggleCarLeasing()
            this.loadCarLeasingList(this.state.car_leasing.car_id)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }else if(action==='edit') {
      //edit
      axios.put(APIURL + '/car_leasing/' + this.state.car_leasing.car_id, data)
        .then((res) => {
          if (res.status === 200) {
            //this.toggleModal();
            //this.loadCarClaim();
            this.toggleCarLeasing()
            this.loadCarLeasingList(this.state.car_leasing.car_id)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

  }

  loadCarLeasingList(car_id) {
    let uri =  APIURL + '/car_leasing/'+car_id;
    axios.get(uri)
      .then(res => {
        this.setState({carLeasingList: res.data})
      }).catch(error => {
      this.setState({carLeasingList: []})
    })
  }

  onSubmitCarRelease(event) {
    event.preventDefault();
    let claim_date = event.target.claim_date.value;
    let claim_name = event.target.claim_name.value; // วันที่ลูกค้ารับรถ
    let claim_mobile = event.target.claim_mobile.value;
    let claim1_list1 = event.target.claim1_list1.value;
    let claim1_list2 = event.target.claim1_list2.value;
    let car_id = event.target.car_id.value;
    let user = this.state.user.user;

    const data = {
      release_date: claim_date,
      release_receive_date: claim_name,
      release_mobile: claim_mobile,
      release1_list1: claim1_list1,
      release1_list2: claim1_list2,
      car_id: car_id,
      user: user
    };

    axios.post(APIURL + '/car_release', data)
      .then((res) => {
        if (res.status === 200) {
          this.toggleCarRelease()
          this.loadCarRelease(car_id)
        }
      })
      .catch((err) => {
        console.log(err);
      });


  }

  loadCarRelease(car_id) {
    axios.get(APIURL + '/car_release/car/'+car_id)
      .then((res) => {

        if (res.status === 200) {
          // this.toggleCarRelease()
          if(res.data.length!==0){
            let car_release = res.data[0];
            let car = this.state.car_release;
            this.printClaim(car_release, car)
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /*async makeCarImgBase64() {
    try {
      let car = this.state.car
      await this.loadCarImageBase64(car.car_upload_id)
    } catch (e) {
      console.log(e)
    }
  }*/

  async loadCarImageBase64(car_upload_id) {
    let uri =  APIURL + '/car/image/' + car_upload_id;
    await axios.get(uri)
        .then(res => {
          let body = res.data.body
          this.setState({ carBase64Img: body })
        }).catch(error => {
          console.log('error:', error)
        })
  }

  printClaim(claimData, carData) {
    // var img_car_claim_id = 'img_'+claimData.car_claim_id;
    let claim_date = moment(claimData.release_date).locale('th').format('LL');
    let claim_name = moment(claimData.release_receive_date).locale('th').format('LL'); //claimData.claim_name;
    let claim_mobile = claimData.release_mobile;
    let claim1_list1 = claimData.release1_list1;
    let claim1_list2 = claimData.release1_list2;
    let car_brand_name = carData.car_brand_name
    let car_model_name = carData.car_model_name
    let car_color_name = carData.car_color_name
    let car_license_plate_new = carData.car_license_plate_new
    let car_upload_filename = carData.car_upload_filename

    // let car_url = IMAGE_NOPHOTO_URL
    //
    // if (car_upload_filename  !== null) {
    //   if (car_upload_filename.includes('https')) {
    //     car_url = car_upload_filename
    //   }else{
    //     car_url = IMAGE_FULL_URL + car_upload_filename
    //   }
    // }

    // this.convertImgToBase64URL(car_url, function(base64Img){
      // Base64DataURL
      let base64Img = this.state.carBase64Img
      let docDefinition = {
        pageOrientation: 'portrait',
        pageSize: 'A4',
        pageMargins: [ 56, 30, 56, 30 ], // margin: [left, top, right, bottom]
        content: [
          { text: 'ใบปล่อยรถ', style: 'bodyhead', lineHeight:1.3},
          {
            columns: [
              { width: '20%',
                text: '', style: 'bodycompany'
              },
              { width: '40%', style: 'bodycompany',
                text: '',
              },
              { width: '20%',
                text: '', style: 'bodycompany'
              },
              { width: '20%', style: 'body',
                text: 'Sale........................',
              },

            ],
            columnGap: 20,
            lineHeight:1
          },
          {
            columns: [
              {
                width: 150,
                //text: 'image'
                image: base64Img
              },
              { width: '80%',
                text: 'วันที่ออกใบงาน...'+ claim_date+ '  \nวันที่ลูกค้ารับรถ...'+claim_name+'   \nเบอร์โทร...' + claim_mobile+'...', style: 'body'
              },
            ],
            columnGap: 20,
            lineHeight:1.3,
            margin: [ 0, 8, 0, 0 ], // margin: [left, top, right, bottom]
          },
          {
            columns: [
              { width: '100%',
                text: 'รถยี่ห้อ...'+ car_brand_name+'  รุ่น...'+car_model_name+ '   สี...'+ car_color_name +'  ทะเบียน...'+car_license_plate_new, style: 'textcenter'
              },
            ],
            columnGap: 20,
            lineHeight: 1.3,
            margin: [ 0, 8, 0, 0 ],
          },
          {
            columns: [
              { width: '100%', text: 'รายการ:', style: 'body' },
            ],
            columnGap: 20,
            margin: [ 0, 8, 0, 0 ], // margin: [left, top, right, bottom]
            lineHeight: 1.5
          },
          {
            columns: [
              { width: '50%', text: claim1_list1 , style: 'body'},
              { width: '50%', text: claim1_list2 , style: 'body'},
            ],
            columnGap: 20,
            margin: [ 8, 0, 0, 0 ], // margin: [left, top, right, bottom]
            lineHeight: 1.3,
          },
          {
            columns: [
              { width: '100%', text: '', style: 'body' },
            ],
            columnGap: 20,
            lineHeight: 1.3
          },
          {
            columns: [
              {
                width: '100%',
                text: 'อนุมัติ....................................   ช่าง:ตรวจ....................................   Sale:ตรวจ....................................   ',
                style: 'textcenter'
              },
            ],
            columnGap: 20,
            lineHeight: 1.5,
            margin: [ 0, 12, 0, 0 ],
          },
          //repeat
          [
            { text: 'ใบปล่อยรถ', style: 'bodyhead', lineHeight:1.3},
            {
              columns: [
                { width: '20%',
                  text: '', style: 'bodycompany'
                },
                { width: '40%', style: 'bodycompany',
                  text: '',
                },
                { width: '20%',
                  text: '', style: 'bodycompany'
                },
                { width: '20%', style: 'body',
                  text: 'Sale........................',
                },

              ],
              columnGap: 20,
              lineHeight:1
            },
            {
              columns: [
                {
                  width: 150,
                  //text: 'image'
                  image: base64Img
                },
                { width: '80%',
                  text: 'วันที่ออกใบงาน...'+ claim_date+ '  \nวันที่ลูกค้ารับรถ...'+claim_name+'   \nเบอร์โทร...' + claim_mobile+'...', style: 'body'
                },
              ],
              columnGap: 20,
              lineHeight:1.3,
              margin: [ 0, 8, 0, 0 ], // margin: [left, top, right, bottom]
            },
            {
              columns: [
                { width: '100%',
                  text: 'รถยี่ห้อ...'+ car_brand_name+'  รุ่น...'+car_model_name+ '   สี...'+ car_color_name +'  ทะเบียน...'+car_license_plate_new, style: 'textcenter'
                },
              ],
              columnGap: 20,
              lineHeight: 1.3,
              margin: [ 0, 8, 0, 0 ],
            },
            {
              columns: [
                { width: '100%', text: 'รายการ:', style: 'body' },
              ],
              columnGap: 20,
              margin: [ 0, 8, 0, 0 ], // margin: [left, top, right, bottom]
              lineHeight: 1.5
            },
            {
              columns: [
                { width: '50%', text: claim1_list1 , style: 'body'},
                { width: '50%', text: claim1_list2 , style: 'body'},
              ],
              columnGap: 20,
              margin: [ 8, 0, 0, 0 ], // margin: [left, top, right, bottom]
              lineHeight: 1.3,
            },
            {
              columns: [
                { width: '100%', text: '', style: 'body' },
              ],
              columnGap: 20,
              lineHeight: 1.3
            },
            {
              columns: [
                {
                  width: '100%',
                  text: 'อนุมัติ....................................   ช่าง:ตรวจ....................................   Sale:ตรวจ....................................   ',
                  style: 'textcenter'
                },
              ],
              columnGap: 20,
              lineHeight: 1.5,
              margin: [ 0, 12, 0, 0 ],
            },
          ]
        ],
        // images: {
        //  carCheck:
        // },
        styles: {
          bodyhead: {
            fontSize: 18,
            bold: true,
            alignment: 'center'
          },
          body: {
            fontSize: 16,
          },
          bodycompany: {
            fontSize: 16,
            bold: true,
          },
          textcenter: {
            fontSize: 16,
            alignment: 'center'
          },
        },
        defaultStyle: {
          font: 'THSarabunNew'
        }
      };
      //

      pdfMake.createPdf(docDefinition).open()

    // });
  }

  convertImgToBase64URL(url, callback, outputFormat){
    let img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function(){
      let canvas = document.createElement('CANVAS'),
        ctx = canvas.getContext('2d'), dataURL;
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
      canvas = null;
    };
    img.src = url;
  }

  // TODO: onChangeCarBook
  onChangeCarBook(car, isModal) {
    this.setState({
      toggle_change_car: isModal,
      change_car: car
    })
  }

  onCarStatusChange(car_id, car_status_id){
    //alert("car_id="+car_id+" , car_status_id="+car_status_id)
    axios.put(APIURL + '/car/car_status/'+car_id,{
      car_id: car_id,
      car_status_id: car_status_id,
    })
        .then(res => {
          this.loadCarData()
          this.toggleCarStatusEdit()
        }).catch(error => {
    })
  }

  render () {

    let all_page = Math.ceil(this.state.count_car/PAGE10);
    let page_array = Array.from(Array(all_page).keys());
    let width = this.state.width

    let tab_pages = page_array.map((page,index)=>{
      let page_tmp = page+1
      return (
        <li className="page-item" key={index}>
          <a className="page-link" href="no-script-url:0"
             onClick={()=>{  this.loadCarData(page_tmp); }}>
            &nbsp;{page_tmp}&nbsp;
          </a></li>
      )
    });
    //  <div className="card">
    // <div className="card-block">
    return (
      <>

        <Loading isLoading={this.state.isLoading} />

        {
          this.state.toggle_change_car &&
              <CarBookChangeCar
                  isOpen={this.state.toggle_change_car}
                  car_sell_pay={this.state.change_car}
                  onToggle={() => {
                    this.setState({
                      toggle_change_car: !this.state.toggle_change_car
                    })
                  }}
                  onReload={() => {
                     this.loadCarData(1)
                     this.loadCountCarData()
                  }}
              />
        }

        <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="card card-accent-info">
                <div className="card-header">
                  <strong className="text-title"> ข้อมูลการจองรถ </strong>

                  <input
                    ref="search_car_name"
                    style={styles.searchCar}
                    className="form-control"
                    placeholder="ค้นหารถชื่อรถ ทะเบียนรถหรือชื่อลูกค้า"
                    type="text"
                  />

                  <button
                    className="btn btn-sm btn-head-bar mr-1 ml-2"
                    onClick={this.searchCar.bind(this)}
                    style={styles.buttonHeight}
                  >
                    <i className="icon-search"/>ค้นหา
                  </button>

                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button className="btn btn-head-bar"
                          style={styles.buttonHeight}
                          onClick={this.onRefreshCar.bind(this)}
                  >
                    <i className="icon-refresh"/>
                  </button>

                 {/* {
                    this.state.user.type === 'admin' || this.state.user.type === 'manager' || this.state.user.type === 'account' ? (
                      <Link to="/report/carbook">
                        <button className="btn btn-head-bar float-right mt-1 ml-1" style={{backgroundColor: '#4dbd74'}}>
                          <i className="icon-doc"></i>&nbsp; รายงานการจอง
                        </button>
                      </Link>
                    ) : null
                  }*/}

                  {
                    checkGroupPermission(701, this.props.grouppermissions).printed ? (
                        <Link to="/report/carbook">
                          <button className="btn btn-head-bar float-right mt-1 ml-1" style={{backgroundColor: '#4dbd74'}}>
                            <i className="icon-doc"></i>&nbsp; รายงานการจอง
                          </button>
                        </Link>
                    ) : null
                  }

                  {
                    checkGroupPermission(1, this.props.grouppermissions).created ? (
                        <Link to="/cars/new">
                          <button className="btn btn-head-bar float-right mt-1">
                            <i className="icon-plus"></i>&nbsp; เพิ่มข้อมูลรถใหม่
                          </button>
                        </Link>
                    ) : null
                  }


                </div>

                <div className="card-block">

                  <table className="table table-striped">
                    <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th className="text-center">รูปรถ</th>
                      <th className="text-center">ชื่อรถ</th>
                      {/*<th className={this.state.user_type ==='admin' ? "text-center" :  "hidden"}>ราคาทุน</th>*/}
                      {/*<th className="text-center">การเงิน</th>*/}
                      {/*<th className={this.state.user_type ==='admin' ? "text-center" :  "hidden"} >กำไร</th>*/}
                      <th className="text-center">สถานะ/การเงิน</th>
                      <th className="text-center">จัดการ</th>
                    </tr>
                    </thead>

                    <CarRowReservation
                      cars={this.state.lists}
                      onCarStatusEdit={this.onCarStatusEdit}
                      onCarCalculate={this.onCarCalculate}
                      onCarShare = {this.onCarShare}
                      onCarDelete = {this.onCarDelete}
                      onCarLeasing={this.onCarLeasing}
                      onCarRelease={this.onCarRelease}
                      user_type={this.state.user_type}
                      carLeasingList = {this.state.carLeasingList}
                      width={width}
                      username={this.state.user.user}
                      grouppermissions={this.props.grouppermissions}
                      onChangeCarBook={this.onChangeCarBook}
                    />

                    {this.state.lists.length === 0 ?
                        <tbody>
                          <tr>
                            <td colSpan={6} className="text-center text-danger">ไม่พบข้อมูล</td>
                          </tr>
                        </tbody>
                      : null }

                  </table>
                  <ul className= { this.state.is_search ? "hidden" : "pagination" }>

                    {tab_pages}

                    {/*<li className="page-item"><a className="page-link" href="#">Prev</a></li>*/}
                    {/*<li className="page-item active"><a className="page-link" href="#">1</a>*/}
                    {/*</li>*/}
                    {/*<li className="page-item"><a className="page-link" href="#">2</a></li>*/}
                    {/*<li className="page-item"><a className="page-link" href="#">3</a></li>*/}
                    {/*<li className="page-item"><a className="page-link" href="#">4</a></li>*/}
                    {/*<li className="page-item"><a className="page-link" href="#">Next</a></li>*/}
                  </ul>
                </div>
              </div>

              <Modal isOpen={this.state.toggle_car_status_edit} toggle={this.toggleCarStatusEdit} className={'modal-lg '}>
                <ModalBody>
                  <CarEditStatus
                    car = {this.state.car_edit_status}
                    onHandle = {
                      {
                        cancel: this.toggleCarStatusEdit,
                        change: this.onCarStatusChange
                      }
                    }
                  />
                </ModalBody>
              </Modal>

              <Modal isOpen={this.state.toggle_car_calculate} toggle={this.toggleCarCalculate} className={'modal-lg ' + this.props.className}>
                <ModalBody>
                  <CarCalculate
                    car={this.state.car_calculate}
                    onToggle={this.toggleCarCalculate}
                  />
                </ModalBody>
              </Modal>

              <Modal isOpen={this.state.toggle_car_share}
                     toggle={this.toggleCarShare}
                     className={'modal-lg ' + this.props.className}
              >
                <ModalBody>
                  <CarShare
                    car={this.state.car_share}
                    onHandle = {
                      {
                        cancel: this.toggleCarShare,
                        change: this.onCarShareChange
                      }
                    }
                  />
                </ModalBody>
              </Modal>


              <Modal isOpen={this.state.toggle_car_status_delete} toggle={this.toggleCarDelete}
                     className={'modal-danger'}>
                <ModalHeader toggle={this.toggleCarDelete}>ลบข้อมูล</ModalHeader>
                <ModalBody>
                  <strong> คุณต้องการลบข้อมูล id={this.state.car_delete.car_id} ใช่หรือไม่ ?? </strong>
                  <br />(ข้อมูลจะไม่สามารถกู้คืนได้)
                </ModalBody>
                <ModalFooter>
                  <Button color="danger"
                          onClick={this.carDelete.bind(this, this.state.car_delete.car_id)}>ตกลง</Button>{' '}
                  <Button color="secondary" onClick={this.toggleCarDelete}>ยกเลิก</Button>

                </ModalFooter>
              </Modal>


              <Modal isOpen={this.state.toggle_car_status_leasing}
                     toggle={this.toggleCarLeasing}
                     className={'modal-md ' + this.props.className}
              >
                <ModalBody>
                  <CarLeasingForm
                    car={this.state.car_leasing}
                    onHandle = {
                      {
                        cancel: this.toggleCarLeasing
                      }
                    }
                    onSubmit = {this.onSubmitCarLeasing}

                  />
                </ModalBody>
              </Modal>


              <Modal isOpen={this.state.toggle_car_release}
                     toggle={this.toggleCarRelease}
                     className={'modal-lg ' + this.props.className}
              >
                <ModalBody>
                  <CarReleaseForm
                    car={this.state.car_release}
                    onHandle = {
                      {
                        cancel: this.toggleCarRelease
                      }
                    }
                    onSubmit = {this.onSubmitCarRelease}
                    onPrintClaim = { async (claimData, carData) => {
                      let car_release = this.state.car_release
                      let car_upload_id = car_release.car_upload_id
                      // console.log(car_upload_id)
                      await this.loadCarImageBase64(car_upload_id)
                      this.printClaim(claimData, carData)
                    }}
                  />
                </ModalBody>
              </Modal>

            </div>
          </div>

      </>
    )
  }

}

const styles = {
  searchCar: {
    marginLeft: 20,
    width: 300,
    display: 'inline'
  },
  buttonHeight: {
    height: 36,
    paddingTop:0,
    paddingBottom:0,
    display: 'inline',
    marginTop: '-0.35rem'
  }
}


const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarReservation)
