import React, {Component} from "react";
import {connect} from "react-redux";
import * as moment from "moment";
import {PAGE20} from "../../../config/config";



export  default class CustomerStoreFrontListRow extends Component{
    render () {
        let customer_all = this.props.customer_all
        //let all_page = Math.ceil(customer_all/PAGE20)
        //let page_array = Array.from(Array(all_page).keys());

        /*let tab_pages = page_array.map((page,index)=>{
            let page_tmp = page+1
            return (
                <li className="page-item" key={index}>
                    <button className="page-link"  onClick={()=>{  this.props.onLoadDataPage(page_tmp) }}>
                        &nbsp;{page_tmp}&nbsp;
                    </button>
                </li>
            )
        });*/

        let customerStoreFrontList = this.props.customer_list.map((customer,index)=>{
            return (
                <tr key={index}>
                    <td className="text-center">{index+1}</td>
                    <td width={'25%'}>
                        ชื่อ-นามสกุล : { customer.customer_name } {' '} { customer.customer_lastname } <br/>
                        วันที่เข้ามาติดต่อ : {moment(customer.walkin_date).locale('th').format('LL')} <br/>
                        เบอร์โทรศัพท์ : { customer.customer_mobile} <br/>
                    </td>
                    <td width={'45%'}>
                        { customer.walkin_note } <br/>
                    </td>
                    <td className="text-center text-nowrap">
                        ระดับ : {customer.walkin_level}<br/>
                        เซลล์ผู้ดูแล : {customer.employee_name}<br/>
                        <strong>แก้ไขล่าสุด : {moment(customer.updated_at).format('DD-MM-YYYY HH:mm:ss')}</strong>
                    </td>
                    <td className="text-center text-nowrap">
                        <button className="btn btn-sm btn-warning btn-md-width mr-2"
                                onClick={this.props.customerStoreFrontCallbacks.edit.bind(null,customer)}
                        >
                            <i className="fa fa-edit"/> แก้ไข</button>

                        <button className="btn btn-sm btn-danger btn-md-width"
                                onClick={this.props.customerStoreFrontCallbacks.delete.bind(null,customer)}
                        >
                            <i className="fa fa-remove"/> ลบ</button>
                    </td>
                </tr>
            )
        });

        let tbEmpty = (
            <tr><td colSpan={4} className="text-danger text-center">ไม่พบข้อมูล</td></tr>
        )

        let tbBody = this.props.customer_list.length===0 ? tbEmpty : customerStoreFrontList

        return (
            <div className="card-block">
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">ข้อมูลลูกค้า</th>
                        <th className="text-center">รายละเอียด</th>
                        <th className="text-center text-nowrap">ระดับความต้องการ</th>
                        <th className="text-center">จัดการ</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tbBody}
                    </tbody>
                </table>

               {/* <ul className= { this.props.is_search ? "hidden" : "pagination d-flex flex-wrap pagination-sm" }>
                    {tab_pages}
                </ul>*/}
            </div>
        )

    }

}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
    }
}